/* 重置CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* 设置rem基准值 */
html {
  font-size: 10px;
}


/* 设置全局默认字体大小 */
body {
  font-size: 1.6rem;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* 防止水平滚动条 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

/* 统一样式 */
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: 600;
}

.icon {
  width: 10px;
  height: 10px;
}

.button_v1 {
  color: #254c83;
  border: none;
  font-size: 15px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 2px 3px rgb(214, 213, 213);
}

/* 主体样式 */
.App {
  /* width: 80%; */
  text-align: center;
  margin: auto;
  height: 100%;
}

/* 响应式设计 */
@media only screen and (max-width: 600px) {
  .App {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .App {
    width: 96%;
    min-width: 800px;
    max-width: 1200px;
  }
}
